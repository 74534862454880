<template>
    <div>
        <Nav/>
        <button v-on:click="logout" class="theme-button">Log out</button>
        <button v-on:click="deleteAccount" class="theme-button">Delete account</button>
        
    </div>
</template>
<script>
import { deleteAccount, logout } from '../api';
import { GlobalState } from '../GlobalState';
import { router } from '../router/index';

export default {
    data(){
        return {
            GlobalState,
        }
    },
    methods: {
        logout(){
            router.push("/");
            logout();
        },
        deleteAccount(){
            deleteAccount(localStorage.getItem("accesstoken")).then(()=>{
                router.push("/");
                logout()
            })
        }
    },
}
</script>
